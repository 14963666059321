'use client';
import React, { memo, useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import dynamic from 'next/dynamic';

import { classNames } from '@/utils';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'highlight.js/styles/github-dark.css';
import { ContentState, EditorState } from 'draft-js';
import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import xml from 'highlight.js/lib/languages/xml';
import htmlToDraft from 'html-to-draftjs';

hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('xml', xml);
// hljs.addPlugin(mergeHTMLPlugin);

interface ITextEditor {
	value: string;
	className?: string;
}
const TextEditor = ({ value, className }: ITextEditor) => {
	const [editorContent, setEditorContent]: any = useState(() =>
		EditorState.createEmpty()
	);

	useEffect(() => {
		const blocksFromHtml = htmlToDraft(value);
		const { contentBlocks, entityMap } = blocksFromHtml;
		const contentState = ContentState.createFromBlockArray(
			contentBlocks,
			entityMap
		);
		setEditorContent(EditorState.createWithContent(contentState));
	}, [value]);

	useEffect(() => {
		setTimeout(() => {
			const preTags = document.getElementsByTagName('pre');
			const size = preTags.length;
			for (let i = 0; i < size; i++) {
				preTags[i].innerHTML =
					'<code >' + preTags[i].innerHTML + '</code>'; // wrap content of pre tag in code tag
			}

			hljs.highlightAll();
		}, 300);
	}, [editorContent]);

	return (
		<div className={classNames('font-montserrat!', className)}>
			<Editor
				readOnly={true}
				editorState={editorContent}
				wrapperClassName={`demo-wrapper`}
				editorClassName={'read-only-editor'}
				toolbarClassName={'hide-toolbar'}
			/>
		</div>
	);
};

export default memo(TextEditor);

export const DynamicEditor = dynamic(() => import('./ReadOnly'), {
	ssr: false,
	loading: () => (
		<div className="flex items-center justify-center w-full h-96"></div>
	),
});
