import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/legacy/image.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/form/TextEditor/ReadOnly.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/general/CText/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/general/ExamButton/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/general/Messenger/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/general/Search/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/general/SmartLink/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/general/Sort/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/layout/Footer/_component/AuthButton.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/layout/Footer/Footer.form.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/layout/Header/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/layout/Viewer/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/view/Guest/CourseDetail/ListLessons/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/view/Guest/CourseDetail/ProgramDetail/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/image/logo/logoc4c.png")