'use client';
import React, { memo, useState } from 'react';
import Link from 'next/link';
import Script from 'next/script';

import { NextImage } from '@/app/_components';

const Messenger: React.FC<{ pageId: string }> = ({ pageId = '' }) => {
	const [showMessenger, setShowMessenger] = useState(false);
	const renderMessenger = () => {
		if (process.env.NODE_ENV === 'development' || !showMessenger)
			return null;
		return (
			<>
				<div id="fb-root"></div>
				<div id="fb-customer-chat" className="fb-customerchat"></div>
				<Script strategy="lazyOnload" id="my-script">
					{`
       var chatbox = document.getElementById('fb-customer-chat');
       chatbox.setAttribute("page_id", "${pageId}");
       chatbox.setAttribute("attribution", "biz_inbox");
       window.fbAsyncInit = function() {
         FB.init({
           xfbml            : true,
           version          : 'v13.0'
         });
       };
 
       (function(d, s, id) {
         var js, fjs = d.getElementsByTagName(s)[0];
         if (d.getElementById(id)) return;
         js = d.createElement(s); js.id = id;
         js.src = 'https://connect.facebook.net/vi_VN/sdk/xfbml.customerchat.js';
         fjs.parentNode.insertBefore(js, fjs);
       }(document, 'script', 'facebook-jssdk'));
  `}
				</Script>
			</>
		);
	};
	return (
		<div className="fixed bottom-[20px] right-[20px] flex flex-row gap-[22px] z-30">
			<Link href="tel:+84706145483" aria-label="call">
				<NextImage
					src={require('./icon-call.svg')}
					width={50}
					height={50}
				/>
			</Link>
			<Link
				href="https://zalo.me/4139202842628470815"
				target={'_blank'}
				rel="noreferrer"
				aria-label={'zalo'}
			>
				<NextImage
					src={require('./icon-zalo.svg')}
					width={52}
					height={50}
				/>
			</Link>
			<button
				title="messenger"
				onClick={() => setShowMessenger(true)}
				className="bg-transparent "
			>
				<NextImage
					src={require('./icon-messenger.svg')}
					width={50}
					height={50}
				/>
			</button>
			{renderMessenger()}
		</div>
	);
};

export default memo(Messenger);
